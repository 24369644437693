'use client';

import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import styles from './extracted-facets.module.scss';
import Button from '~/components/button';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';
import DropDown from '~/components/dropdown';
import { CONDITIONS } from 'sdk';

interface ConditionCount {
  value: string;
  count: number;
}

interface BookConditionSelectionProps {
  conditionCounts?: ConditionCount[];
}

/**
 * Book condition options available for the bookstore
 * Each option represents a book condition that can be filtered
 */
const CONDITION_OPTIONS = CONDITIONS;

/**
 * BookConditionSelection component
 *
 * Allows users to filter books by condition in the bookstore.
 * Uses URL parameters to maintain filter state between page loads.
 */
export default function BookConditionSelection({
  conditionCounts = [],
}: BookConditionSelectionProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  /**
   * Get the current selected conditions from URL parameters
   */
  const getSelectedConditions = () => {
    // Look for conditions in the filter parameter
    const filterString = searchParams.get('filter') || '';
    const selectedConditions: string[] = [];

    // Check if any condition is present in the filter string
    for (const condition of CONDITIONS) {
      if (filterString.includes(`condition:=${condition}`)) {
        selectedConditions.push(condition);
      }
    }

    return selectedConditions;
  };

  /**
   * Helper to get count for a specific condition
   * Uses initial counts to ensure counts don't disappear when filtering
   */
  const getConditionCount = (condition: string): number => {
    // Use the initial counts which don't change when filtering
    const matchingInitialCondition = conditionCounts.find(
      (item) => item.value === condition
    );
    return matchingInitialCondition?.count || 0;
  };

  /**
   * Get the total count for all conditions
   */
  const getTotalCount = (): number => {
    return conditionCounts.reduce((sum, item) => sum + item.count, 0);
  };

  const getConditionLabel = () => {
    const selectedConditions = getSelectedConditions();

    if (selectedConditions.length === 0) {
      const total = getTotalCount();
      return total > 0 ? `All Conditions` : 'All Conditions';
    } else if (selectedConditions.length === 1) {
      const count = getConditionCount(selectedConditions[0]);
      return count > 0 ? `${selectedConditions[0]}` : selectedConditions[0];
    } else {
      return `${selectedConditions.length} Conditions`;
    }
  };

  /**
   * Handle condition selection change
   * Updates URL parameters and resets to page 1
   */
  const handleConditionChange = (condition: string) => {
    const params = new URLSearchParams(searchParams);
    const selectedConditions = getSelectedConditions();
    const isSelected = selectedConditions.includes(condition);

    // Create a new list of selected conditions
    let newSelectedConditions: string[] = [...selectedConditions];

    if (isSelected) {
      // Remove the condition if already selected
      newSelectedConditions = newSelectedConditions.filter(
        (c) => c !== condition
      );
    } else {
      // Add the condition if not already selected
      newSelectedConditions.push(condition);
    }

    // Get existing filter string and remove any existing condition filter groups
    let filterString = params.get('filter') || '';

    // Remove existing condition filter group if it exists
    // This regex matches condition filter groups like (condition:=Value1 || condition:=Value2)
    filterString = filterString.replace(/\(condition:=[^)]+\)(\s*&&\s*)?/g, '');
    filterString = filterString.replace(/^&&\s*|\s*&&\s*$/g, '').trim();

    // Add the new condition filter group if there are selected conditions
    if (newSelectedConditions.length > 0) {
      // Create a properly grouped condition filter
      const conditionFilter = `(${newSelectedConditions
        .map((c) => `condition:=${c}`)
        .join(' || ')})`;

      // Add to existing filter with proper && operator
      if (filterString) {
        filterString = `${filterString} && ${conditionFilter}`;
      } else {
        filterString = conditionFilter;
      }
    }

    // Update or remove the filter parameter
    if (filterString.trim()) {
      params.set('filter', filterString.trim());
    } else {
      params.delete('filter');
    }

    // Reset to first page when condition changes
    params.set('page', '1');

    router.push(`${pathname}?${params.toString()}`, { scroll: false });
    setIsOpen(false);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const IS_MOBILE = window.innerWidth < 768;
      setIsMobile(IS_MOBILE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectedConditions = getSelectedConditions();
  const isConditionSelected = selectedConditions.length > 0;

  function dropdownArrow(open: boolean) {
    return (
      <div className={styles.arrow}>
        {open ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  function conditionDropdown() {
    const conditionOptions = CONDITIONS.map((condition) => {
      const count = getConditionCount(condition);
      const isSelected = selectedConditions.includes(condition);

      return (
        <div
          key={condition}
          className={`${styles['options-row']} ${
            isSelected ? styles.selected : ''
          }`}
          onClick={() => handleConditionChange(condition)}
        >
          {condition}
          <span className={styles.count}>{count}</span>
        </div>
      );
    });

    return conditionOptions;
  }

  return (
    <div className={styles['parent-container']} ref={dropdownRef}>
      <Button
        text={getConditionLabel()}
        style="outline"
        size="xtra-small"
        className={`${styles['filter-buttons']} ${
          isConditionSelected ? styles['filter-buttons-selected'] : ''
        }`}
        iconPosition="right"
        icon={dropdownArrow(isOpen)}
        onPress={() => setIsOpen(!isOpen)}
      />

      {isOpen && !isMobile && (
        <div className={styles['dropdown-menu']}>
          <DropDown open={isOpen} onHide={() => setIsOpen(false)}>
            <div className={styles.options}>{conditionDropdown()}</div>
          </DropDown>
        </div>
      )}

      {isOpen && isMobile && (
        <div className={styles['dropdown-menu-mobile']}>
          <div className={styles.options}>{conditionDropdown()}</div>
        </div>
      )}
    </div>
  );
}
