'use client';

import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import styles from './extracted-facets.module.scss';
import Button from '~/components/button';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';
import DropDown from '~/components/dropdown';

interface FormatCount {
  value: string;
  count: number;
}

interface BookTypeSelectionProps {
  formatCounts?: FormatCount[];
}

/**
 * BookTypeSelection component
 *
 * Allows users to filter books by format type (Hardcover or Paperback)
 * Uses URL parameters to maintain filter state between page loads.
 */
export default function BookTypeSelection({
  formatCounts = [],
}: BookTypeSelectionProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  /**
   * Get the current format filter from URL parameters
   * Returns empty string if no format parameter is set
   */
  const getCurrentFormat = () => {
    return searchParams.get('format') || '';
  };

  /**
   * Get the count for the selected format
   */
  const getFormatCount = (format: string): number => {
    if (!format) {
      // For "All Formats", return total count
      return formatCounts.reduce((sum, item) => sum + item.count, 0);
    }

    // Find count for specific format
    const matchingFormat = formatCounts.find((item) => item.value === format);
    return matchingFormat?.count || 0;
  };

  /**
   * Get the button text to display based on selected format
   */
  const getButtonText = (): string => {
    const currentFormat = getCurrentFormat();
    const count = getFormatCount(currentFormat);

    if (!currentFormat) {
      return count > 0 ? `All Formats` : 'All Formats';
    }

    return count > 0 ? `${currentFormat}` : currentFormat;
  };

  /**
   * Handle format change event
   * Updates URL parameters and resets to page 1
   */
  const handleFormatChange = (format: string) => {
    const params = new URLSearchParams(searchParams);

    if (format) {
      params.set('format', format);
    } else {
      params.delete('format');
    }

    params.set('page', '1'); // Reset to first page when format changes

    // Use push with scroll: false to prevent page scrolling
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
    setIsOpen(false);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const IS_MOBILE = window.innerWidth < 768;
      setIsMobile(IS_MOBILE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentFormat = getCurrentFormat();
  const isFormatSelected = currentFormat !== '';

  function dropdownArrow(open: boolean) {
    return (
      <div className={styles.arrow}>
        {open ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  function formatDropdown() {
    const formatOptions = formatCounts.map((format) => {
      const { count, value } = format;

      return (
        <div
          key={value}
          className={`${styles['options-row']} ${
            currentFormat === value ? styles.selected : ''
          }`}
          onClick={() => handleFormatChange(value)}
        >
          {value}
          <span className={styles.count}>{count}</span>
        </div>
      );
    });

    // Add "All Formats" option
    const allFormatsCount = formatCounts.reduce(
      (sum, item) => sum + item.count,
      0
    );
    formatOptions.unshift(
      <div
        key="all-formats"
        className={`${styles['options-row']} ${
          currentFormat === '' ? styles.selected : ''
        }`}
        onClick={() => handleFormatChange('')}
      >
        All Formats
        <span className={styles.count}>{allFormatsCount}</span>
      </div>
    );

    return formatOptions;
  }

  return (
    <div className={styles['parent-container']} ref={dropdownRef}>
      <Button
        text={getButtonText()}
        style="outline"
        size="xtra-small"
        className={`${styles['filter-buttons']} ${
          isFormatSelected ? styles['filter-buttons-selected'] : ''
        }`}
        iconPosition="right"
        icon={dropdownArrow(isOpen)}
        onPress={() => setIsOpen(!isOpen)}
      />

      {isOpen && !isMobile && (
        <div className={styles['dropdown-menu']}>
          <DropDown open={isOpen} onHide={() => setIsOpen(false)}>
            <div className={styles.options}>{formatDropdown()}</div>
          </DropDown>
        </div>
      )}

      {isOpen && isMobile && (
        <div className={styles['dropdown-menu-mobile']}>
          <div className={styles.options}>{formatDropdown()}</div>
        </div>
      )}
    </div>
  );
}
