'use client';

import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import styles from './extracted-facets.module.scss';
import Button from '~/components/button';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';
import DropDown from '~/components/dropdown';
import { isFacetFilterSelected, numberWithCommas } from 'sdk';
import { TypesenseSearchProps } from 'types';

interface BookTagsProps {
  facetCounts: any; // The full search result object
  searchOptions: TypesenseSearchProps;
  handleOnClick: (field: string, value: string) => void;
}

// Fallback tags for when API doesn't provide any
const FALLBACK_TAGS = [];

export default function BookTags({
  facetCounts,
  searchOptions,
  handleOnClick,
}: BookTagsProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);

  // Extract hashtag facets from facet_counts
  let tagFacets = FALLBACK_TAGS;

  // Try to get facet_counts from the facetCounts object
  if (facetCounts?.facet_counts) {
    const hashtagFacets = facetCounts.facet_counts.find(
      (c) => c.field_name === 'hashtags'
    )?.counts;

    if (hashtagFacets?.length) {
      // Sanitize facets to ensure no NaN values
      tagFacets = hashtagFacets.map((facet) => ({
        value: facet.value,
        count:
          typeof facet.count === 'number' && !isNaN(facet.count)
            ? facet.count
            : 0,
      }));
    }
  }

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const IS_MOBILE = window.innerWidth < 768;
      setIsMobile(IS_MOBILE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!tagFacets?.length) return null;

  // Get selected tags from URL
  const getSelectedTags = () => {
    const filterString = searchParams.get('filter') || '';
    const selectedTags: string[] = [];

    tagFacets.forEach((tag) => {
      if (isFacetFilterSelected(filterString, 'hashtags', tag.value)) {
        selectedTags.push(tag.value);
      }
    });

    return selectedTags;
  };

  const selectedTags = getSelectedTags();
  const isTagSelected = selectedTags.length > 0;

  // Create label for button
  const getTagLabel = () => {
    if (selectedTags.length === 0) {
      return 'Tags';
    } else if (selectedTags.length === 1) {
      return `#${selectedTags[0].replace(/^#/, '')}`;
    } else {
      return `${selectedTags.length} Tags`;
    }
  };

  function dropdownArrow(open: boolean) {
    return (
      <div className={styles.arrow}>
        {open ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  // Handle tag click to toggle filters
  const handleTagClick = (value: string) => {
    const params = new URLSearchParams(searchParams);
    const currentFilter = params.get('filter') || '';
    const field = 'hashtags';

    // Check if this tag is already selected
    const isSelected = isFacetFilterSelected(currentFilter, field, value);

    let newFilter = '';

    if (isSelected) {
      // Remove the tag filter
      const tagFilter = `${field}:${value}`;
      const regex = new RegExp(
        `(${tagFilter})(\\s*&&\\s*)?|\\s*&&\\s*(${tagFilter})`
      );
      newFilter = currentFilter.replace(regex, '').trim();
    } else {
      // Add the tag filter
      const tagFilter = `${field}:${value}`;
      newFilter = currentFilter
        ? `${currentFilter} && ${tagFilter}`
        : tagFilter;
    }

    // Update or remove the filter parameter
    if (newFilter) {
      params.set('filter', newFilter);
    } else {
      params.delete('filter');
    }

    // Reset to page 1
    params.set('page', '1');

    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  };

  function tagDropdown() {
    const tagsToShow = showAllTags ? tagFacets : tagFacets.slice(0, 10);

    const tagOptions = tagsToShow.map((tag) => {
      const { count, value } = tag;
      const filterString = searchParams.get('filter') || '';
      const isSelected = isFacetFilterSelected(filterString, 'hashtags', value);

      // Ensure count is a valid number for display
      const safeCount = typeof count === 'number' && !isNaN(count) ? count : 0;

      return (
        <div
          key={value}
          className={`${styles['options-row']} ${
            isSelected ? styles.selected : ''
          }`}
          onClick={() => handleTagClick(value)}
        >
          #{value.replace(/^#/, '')}
          <span className={styles.count}>{safeCount}</span>
        </div>
      );
    });

    return (
      <div className={styles.options}>
        {tagOptions}
        {tagFacets.length > 10 && (
          <div
            className={`${styles['options-row']} ${styles['show-more']}`}
            onClick={() => setShowAllTags(!showAllTags)}
          >
            {showAllTags ? 'Show Less' : 'Show More'}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles['parent-container']} ref={dropdownRef}>
      <Button
        text={getTagLabel()}
        style="outline"
        size="xtra-small"
        className={`${styles['filter-buttons']} ${
          isTagSelected ? styles['filter-buttons-selected'] : ''
        }`}
        iconPosition="right"
        icon={dropdownArrow(isOpen)}
        onPress={() => setIsOpen(!isOpen)}
      />

      {isOpen && !isMobile && (
        <div className={styles['dropdown-menu']}>
          <DropDown open={isOpen} onHide={() => setIsOpen(false)}>
            {tagDropdown()}
          </DropDown>
        </div>
      )}

      {isOpen && isMobile && (
        <div className={styles['dropdown-menu-mobile']}>{tagDropdown()}</div>
      )}
    </div>
  );
}
