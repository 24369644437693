'use client';

import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import formStyles from '../../styles.module.scss';
import facetStyles from './extracted-facets.module.scss';
import Button from '~/components/button';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';

import type { TypesenseSearchProps } from 'types';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import { Book, Title } from 'types';
import useAnalytics from '@/lib/hooks/use-analytics';

const MAX_PRICE = 10000;
const MIN_PRICE = 0;

// TypesenseSearchResult type for compatibility with page component
interface TypesenseSearchResult {
  hits: any[];
  found: number;
  facet_counts?: {
    field_name: string;
    counts: { value: string; count: number }[];
  }[];
}

interface PriceSelectionProps {
  searchResults:
    | UseQueryResult<SearchResponse<Book | Title>, unknown>
    | TypesenseSearchResult;
  searchOptions: TypesenseSearchProps;
  location?: string;
}

export default function PriceSelection(props: PriceSelectionProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { analyticEvents } = useAnalytics();

  // State for min and max price input values
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  // Handle both React Query result and direct TypesenseSearchResult
  const getFacetCounts = () => {
    if ('data' in props.searchResults) {
      // This is a React Query result
      return props.searchResults?.data?.facet_counts;
    } else {
      // This is a direct TypesenseSearchResult
      return props.searchResults?.facet_counts;
    }
  };

  const amountFacet = getFacetCounts()?.find(
    (facet) => (facet.field_name as string) === 'amount'
  );

  // Get the total number of books matching the current price filter
  const getMatchingBooksCount = (): number => {
    if ('data' in props.searchResults) {
      return props.searchResults?.data?.found || 0;
    } else {
      return props.searchResults?.found || 0;
    }
  };

  // Get current price range from URL parameters
  useEffect(() => {
    const filterParam = searchParams.get('filter') || '';

    // Try to extract amount filter from filter param string
    const amountFilter =
      filterParam.match(/amount:\[(\d+)\.\.(\d+)\]/) ||
      filterParam.match(/amount:>=(\d+)/);

    if (amountFilter) {
      // Handle the case where there's a range
      if (amountFilter.length === 3) {
        const min = parseInt(amountFilter[1]);
        const max = parseInt(amountFilter[2]);

        // Convert from cents to dollars
        setMinPrice((min / 100).toString());
        setMaxPrice((max / 100).toString());
      }
      // Handle the case where it's just a minimum (amount:>=X)
      else if (amountFilter.length === 2) {
        const min = parseInt(amountFilter[1]);

        // Convert from cents to dollars
        setMinPrice((min / 100).toString());
        setMaxPrice('');
      }
    } else {
      // No price filter, clear inputs
      setMinPrice('');
      setMaxPrice('');
    }
  }, [searchParams]);

  // Handle price filter application
  const handleApplyPriceFilter = () => {
    // Create new URL with updated filter parameter
    const params = new URLSearchParams(searchParams);

    // Convert input values to cents for the filter
    const minInCents = minPrice ? Math.round(parseFloat(minPrice) * 100) : null;
    const maxInCents = maxPrice ? Math.round(parseFloat(maxPrice) * 100) : null;

    // Get existing filter and remove any existing amount filters
    let filterParam = params.get('filter') || '';

    // Remove price filter patterns, being careful to preserve other filters
    // This regex handles both standalone price filters and ones combined with &&
    filterParam = filterParam
      .replace(/(\s*&&\s*)?amount:\[\d+\.\.\d+\](\s*&&\s*)?/g, ' && ')
      .replace(/(\s*&&\s*)?amount:>=\d+(\s*&&\s*)?/g, ' && ')
      .replace(/(\s*&&\s*)?amount:<=\d+(\s*&&\s*)?/g, ' && ');

    // Clean up any leftover && operators at start/end or double &&
    filterParam = filterParam
      .replace(/^\s*&&\s*|\s*&&\s*$/g, '')
      .replace(/\s*&&\s*&&\s*/g, ' && ')
      .trim();

    // Build the new amount filter if values are provided
    if (minInCents !== null || maxInCents !== null) {
      let amountFilter;

      if (minInCents !== null && maxInCents !== null) {
        // Both min and max provided
        amountFilter = `amount:[${minInCents}..${maxInCents}]`;
      } else if (minInCents !== null) {
        // Only min provided
        amountFilter = `amount:>=${minInCents}`;
      } else if (maxInCents !== null) {
        // Only max provided
        amountFilter = `amount:<=${maxInCents}`;
      }

      // Track the action
      analyticEvents?.generalTrack('Search Action', {
        type: 'filter-action',
        subType: 'price',
        subTypeValue: {
          minValue: minInCents,
          maxValue: maxInCents,
        },
        location: props.location || 'unknown',
      });

      // Add the new amount filter to existing filters if any
      if (filterParam) {
        filterParam = `${filterParam.trim()} && ${amountFilter}`;
      } else {
        filterParam = amountFilter;
      }

      params.set('filter', filterParam);
    } else {
      // No price filters, remove the filter param if it's empty
      if (!filterParam) {
        params.delete('filter');
      } else {
        params.set('filter', filterParam);
      }
    }

    // Reset to first page when filter changes
    params.set('page', '1');

    // Use push with scroll: false to prevent page scrolling
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get display text for the price filter button
  const getPriceDisplayText = () => {
    const matchingCount = getMatchingBooksCount();

    if (!minPrice && !maxPrice) {
      return matchingCount > 0 ? `Any Price` : 'Any Price';
    }

    if (minPrice && !maxPrice) {
      return matchingCount > 0 ? `$${minPrice}+` : `$${minPrice}+`;
    }

    if (!minPrice && maxPrice) {
      return matchingCount > 0 ? `Up to $${maxPrice}` : `Up to $${maxPrice}`;
    }

    return matchingCount > 0 ? `$${minPrice} - $${maxPrice}` : `$${minPrice} - $${maxPrice}`;
  };

  // Add helper function for dropdown arrow
  function dropdownArrow(open: boolean) {
    return (
      <div className={facetStyles.arrow}>
        {open ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  // Check if price filter is active
  const isPriceFilterActive = minPrice !== '' || maxPrice !== '';

  return (
    <div className={facetStyles['parent-container']} ref={dropdownRef}>
      <Button
        text={getPriceDisplayText()}
        style="outline"
        size="xtra-small"
        className={`${facetStyles['filter-buttons']} ${
          isPriceFilterActive ? facetStyles['filter-buttons-selected'] : ''
        }`}
        iconPosition="right"
        icon={dropdownArrow(isOpen)}
        onPress={() => setIsOpen(!isOpen)}
      />

      {isOpen && (
        <div className={facetStyles['dropdown-menu-format']}>
          <div className={formStyles.priceInputContainer}>
            <div className={formStyles.priceRangeInputs}>
              <div className={formStyles.priceInputWrapper}>
                <input
                  type="number"
                  placeholder="min"
                  min="0"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  className={formStyles.priceInput}
                />
              </div>
              <div className={formStyles.priceInputWrapper}>
                <input
                  type="number"
                  placeholder="max"
                  min="0"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  className={formStyles.priceInput}
                />
              </div>
              <button
                className={formStyles.priceSearchButton}
                onClick={handleApplyPriceFilter}
                aria-label="Apply price filter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
