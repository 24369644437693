'use client';

import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import styles from './extracted-facets.module.scss';
// import styles from '../styles.module.scss';
import {
  SORT_SELECT_OPTIONS,
  parseSortBy,
  sortSelectValue,
  parseSortSelectValue,
} from 'sdk';
import type { TypesenseSearchProps } from 'types';
import Button from '~/components/button';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import COLORS from '~/lib/helpers/color-helper';
import DropDown from '~/components/dropdown';

/**
 * Book sort options available for the bookstore
 * Each option has a name for display and a value for the query parameter
 */
const BOOK_SORT_OPTIONS = SORT_SELECT_OPTIONS.filter(
  (option) => option.index === 'books'
);

/**
 * SortSelect component
 *
 * Allows users to change the sorting order of books in the bookstore.
 * Uses URL parameters to maintain sort state between page loads.
 */
export default function SortSelect() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  /**
   * Get the current sort value from URL parameters
   * Defaults to the first option if no sort parameter is set
   */
  const getCurrentSortValue = () => {
    const sortParam = searchParams.get('sort');
    if (!sortParam) {
      return BOOK_SORT_OPTIONS[0].value;
    }

    // Find the book sort option that matches this sort_by value
    const matchedOption = BOOK_SORT_OPTIONS.find((option) => {
      const { sort_by } = parseSortSelectValue(option.value);
      return sort_by === sortParam;
    });

    return matchedOption ? matchedOption.value : BOOK_SORT_OPTIONS[0].value;
  };

  const getCurrentSortName = () => {
    const currentValue = getCurrentSortValue();
    const option = BOOK_SORT_OPTIONS.find(
      (option) => option.value === currentValue
    );
    return option ? option.name : BOOK_SORT_OPTIONS[0].name;
  };

  /**
   * Check if current sort is not the default (first option)
   */
  const isNonDefaultSort = () => {
    const currentValue = getCurrentSortValue();
    return currentValue !== BOOK_SORT_OPTIONS[0].value;
  };

  /**
   * Handle sort change event
   * Updates URL parameters and resets to page 1
   */
  const handleSortChange = (value: string) => {
    const { sort_by } = parseSortSelectValue(value);

    // Create new URL with updated sort parameter
    const params = new URLSearchParams(searchParams);
    params.set('sort', sort_by);
    params.set('page', '1'); // Reset to first page when sort changes

    // Use push with scroll: false to prevent page scrolling
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
    setIsOpen(false);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const IS_MOBILE = window.innerWidth < 768;
      setIsMobile(IS_MOBILE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function dropdownArrow(open: boolean) {
    return (
      <div className={styles.arrow}>
        {open ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  const currentValue = getCurrentSortValue();
  const isCustomSort = isNonDefaultSort();

  return (
    <div className={styles['parent-container']} ref={dropdownRef}>
      <span className={styles['sort-label']}>Sort:</span>
      <Button
        text={getCurrentSortName()}
        style="outline"
        size="xtra-small"
        className={`${styles['filter-buttons']} ${
          isCustomSort ? styles['filter-buttons-selected'] : ''
        }`}
        iconPosition="right"
        icon={dropdownArrow(isOpen)}
        onPress={() => setIsOpen(!isOpen)}
      />

      {isOpen && !isMobile && (
        <div className={styles['dropdown-menu']}>
          <DropDown open={isOpen} onHide={() => setIsOpen(false)}>
            <div className={styles.options}>
              {BOOK_SORT_OPTIONS.map((option) => (
                <div
                  key={option.value}
                  className={`${styles['options-row']} ${
                    currentValue === option.value ? styles.selected : ''
                  }`}
                  onClick={() => handleSortChange(option.value)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </DropDown>
        </div>
      )}

      {isOpen && isMobile && (
        <div className={styles['dropdown-menu-mobile']}>
          <div className={styles.options}>
            {BOOK_SORT_OPTIONS.map((option) => (
              <div
                key={option.value}
                className={`${styles['options-row']} ${
                  currentValue === option.value ? styles.selected : ''
                }`}
                onClick={() => handleSortChange(option.value)}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
