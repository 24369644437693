'use client';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  isFastShipper,
  isHighQualitySeller,
  lastActiveTime,
  possessive,
} from 'sdk';
import { getFollowData } from '~/clients/firebase/client';
import { useGlobalState } from '~/state';
import { FollowData } from '~/clients/types';
import { UserProfile } from 'types';
import { ActionType } from '~/state/types';
import { Share } from '~/lib/helpers/share-helper';
import { Links } from '~/lib/helpers/link-helper';

import UserProfileImage from '~/components/user-profile-image';
import UserReviewStars from '~/components/user-review-stars';
import FollowButton from '~/components/follow-button';
import Pill from '~/components/pill';
import Button from '~/components/button';
import BookReviews from '~/components/book-reviews';
import Spacer from '~/components/spacer';

import QualitySVG from '~/assets/svg/icons/quality';
import FastShipperSVG from '~/assets/svg/icons/fast-shipper';
import MessageSVG from '~/assets/svg/icons/message';
import ShareSVG from '~/assets/svg/icons/share';
import SnackSvgStar from '~/assets/svg/icons/snack-svg-star';
import ChevronRightSVG from '~/assets/svg/icons/chevron-right';
import MosaicSVG from '~/assets/svg/icons/mosaic';
import COLORS from '~/lib/helpers/color-helper';

import styles from '../styles.module.scss';

interface BookstoreHeaderProps {
  seller: UserProfile;
  username: string;
}

export default function BookstoreHeader({
  seller,
  username,
}: BookstoreHeaderProps) {
  const { state, dispatch } = useGlobalState();
  const [followData, setFollowData] = useState<FollowData>(null);

  useEffect(() => {
    async function fetchFollow() {
      const data = await getFollowData(seller.id);
      setFollowData(data);
    }

    fetchFollow();
  }, [seller.id]);

  function hasBadges() {
    return isHighQualitySeller(seller) || isFastShipper(seller);
  }

  const handleShare = async () => {
    const title = 'Check it out!';
    const text = `${possessive(seller.name)} bookstore on PangoBooks`;
    const url = window.location.href;
    Share.link({
      title,
      text,
      url,
    });
  };

  function renderBookReviews() {
    if (!seller?.book_reviews) return null;

    function handleShowBookReviews() {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          large: true,
          component: (
            <>
              <div className={styles['review-modal-title']}>{`${possessive(
                seller?.name
              )} Book Reviews`}</div>
              <Spacer />
              <Spacer />
              <BookReviews user_id={seller?.id} type="user" style="full" />
            </>
          ),
        },
      });
    }

    return (
      <div className={styles['action-buttons']} onClick={handleShowBookReviews}>
        <div className={styles['content']}>
          <div className={styles['content-icon']}>
            <SnackSvgStar />
          </div>
          <div className={styles['title']}>Book Reviews</div>
          <div className={styles['count']}>( {seller?.book_reviews} ) </div>
        </div>
        <div className={styles['icon']}>
          <ChevronRightSVG strokeColor={COLORS.grey} />
        </div>
      </div>
    );
  }

  function renderShelves() {
    return (
      <Link href={`/shelves/${username}`}>
        <div className={styles['action-buttons']}>
          <div className={styles['content']}>
            <div className={styles['content-icon']}>
              <MosaicSVG />
            </div>
            <div className={styles['title']}>Shelves</div>
          </div>
          <div className={styles['icon']}>
            <ChevronRightSVG strokeColor={COLORS.grey} />
          </div>
        </div>
      </Link>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles['header-container']}>
        <div className={styles['header-seller-info']}>
          <div className={styles['header-seller-info-container']}>
            <div className={styles['image-active-container']}>
              <div className={styles['header-seller-info-image']}>
                <UserProfileImage user={seller} link={false} />
              </div>
              {lastActiveTime(seller) && (
                <div className={styles['pill']}>
                  <Pill text={lastActiveTime(seller)} small shadow />
                </div>
              )}
            </div>
            <div className={styles['header-seller-info-content']}>
              <h1>{seller.name}</h1>
              <h2>@{seller.username}</h2>
              <UserReviewStars
                user={seller}
                withCountText
                cssClass={styles['header-seller-info-reviews']}
              />
              {hasBadges() && (
                <div className={styles['header-seller-badges']}>
                  {isHighQualitySeller(seller) && (
                    <Pill
                      icon={<QualitySVG />}
                      text="High Quality"
                      className={styles['header-seller-badges-pill']}
                    />
                  )}
                  {isFastShipper(seller) && (
                    <Pill
                      icon={<FastShipperSVG />}
                      text="Ultra Fast Shipper"
                      className={styles['header-seller-badges-pill']}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {seller.bio && <p>{seller.bio}</p>}
        </div>
        <div className={styles['header-seller-actions']}>
          <div className={styles['header-seller-stats']}>
            <div className={styles['header-seller-stats-stat']}>
              <h3>Books Listed</h3>
              <span>{seller?.books_listed || 0}</span>
            </div>
            <div className={styles['header-seller-stats-stat']}>
              <h3>Following</h3>
              <span>{followData?.following || 0}</span>
            </div>
            <div className={styles['header-seller-stats-stat']}>
              <h3>Followers</h3>
              <span>{followData?.followers || 0}</span>
            </div>
          </div>

          <div className={styles['header-seller-buttons']}>
            <div className={styles['header-seller-buttons--follow']}>
              <FollowButton seller={seller} />
            </div>

            <Button
              style="secondary"
              icon={<MessageSVG fillColor={COLORS.primary} />}
              link={
                seller?.id && {
                  href: Links.messages.general({
                    type: 'userId',
                    id: seller.id,
                  }),
                }
              }
              disabled={seller?.id === state?.user?.data?.uid}
            />
            <Button
              style="secondary"
              icon={<ShareSVG />}
              onPress={handleShare}
            />
          </div>

          <div className={styles['header-seller-buttons--action']}>
            {renderBookReviews()}
            {renderShelves()}
          </div>
        </div>
      </div>
    </header>
  );
}
