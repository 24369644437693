'use client';

import { Book } from 'types';
import BookTile from '@/components/tiles/book';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import styles from '../styles.module.scss';

interface BookGridProps {
  books: Book[];
  totalBooks: number;
  currentPage: number;
  totalPages: number;
}

export default function BookGrid({
  books,
  totalBooks,
  currentPage,
  totalPages,
}: BookGridProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Function to create a URL with updated params
  const createPageUrl = (pageNumber: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', pageNumber.toString());
    return `${pathname}?${params.toString()}`;
  };

  // Determine which page numbers to show
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      // Show all pages if there are few
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show first page
      pageNumbers.push(1);

      // Calculate start and end pages to show
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      // Adjust if we're near the start
      if (currentPage <= 3) {
        endPage = Math.min(totalPages - 1, 4);
      }

      // Adjust if we're near the end
      if (currentPage >= totalPages - 2) {
        startPage = Math.max(2, totalPages - 3);
      }

      // Add ellipsis after first page if needed
      if (startPage > 2) {
        pageNumbers.push('...');
      }

      // Add middle pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Add ellipsis before last page if needed
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      // Always show last page
      if (totalPages > 1) {
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Header with results count and sort dropdown */}
      <div className={styles.resultsHeader}>
        {/* Empty header - sorting has been moved to parent component */}
      </div>

      {/* Grid of books */}
      {books.length > 0 ? (
        <div className={styles.grid}>
          {books.map((book) => (
            <BookTile book={book} key={book.id} source="search-results" />
          ))}
        </div>
      ) : (
        <div className={styles['empty-state']}>
          <p>No books found. Try adjusting your search.</p>
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className={styles.pagination}>
          {/* Previous button */}
          {currentPage > 1 ? (
            <Link
              href={createPageUrl(currentPage - 1)}
              className={styles.pageLink}
            >
              &laquo; Previous
            </Link>
          ) : (
            <span className={`${styles.pageLink} ${styles.disabled}`}>
              &laquo; Previous
            </span>
          )}

          {/* Page numbers */}
          {getPageNumbers().map((page, index) =>
            typeof page === 'number' ? (
              <Link
                href={createPageUrl(page)}
                key={index}
                className={`${styles.pageLink} ${
                  currentPage === page ? styles.active : ''
                }`}
              >
                {page}
              </Link>
            ) : (
              <span key={index} className={styles.ellipsis}>
                {page}
              </span>
            )
          )}

          {/* Next button */}
          {currentPage < totalPages ? (
            <Link
              href={createPageUrl(currentPage + 1)}
              className={styles.pageLink}
            >
              Next &raquo;
            </Link>
          ) : (
            <span className={`${styles.pageLink} ${styles.disabled}`}>
              Next &raquo;
            </span>
          )}
        </div>
      )}
    </div>
  );
}
