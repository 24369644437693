'use client';

import { useState, useEffect } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import SearchIcon from '@/assets/svg/icons/search';
import CloseSVG from '@/assets/svg/icons/close';
import COLORS from '@/lib/helpers/color-helper';
import styles from '../styles.module.scss';

interface BookSearchFormProps {
  initialQuery?: string;
}

export default function BookSearchForm({
  initialQuery = '',
}: BookSearchFormProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [inputIsEmpty, setInputIsEmpty] = useState(!initialQuery);

  // When initialQuery from URL changes, update local state
  useEffect(() => {
    setSearchQuery(initialQuery);
    setInputIsEmpty(!initialQuery);
  }, [initialQuery]);

  // Handle input change
  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setInputIsEmpty(value.length === 0);
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Create a new URLSearchParams and preserve existing parameters except page
    const params = new URLSearchParams(searchParams);

    // Update or remove the query parameter
    if (searchQuery.trim()) {
      params.set('q', searchQuery);
    } else {
      params.delete('q');
    }

    // Reset to page 1 when search changes
    params.set('page', '1');

    // Navigate to the new URL
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchQuery('');
    setInputIsEmpty(true);
    updateSearchParams('');
  };

  // Update URL search params
  const updateSearchParams = (query: string) => {
    const params = new URLSearchParams(searchParams);

    // Update query parameter
    if (query) {
      params.set('q', query);
    } else {
      params.delete('q');
    }

    // Reset to page 1 when search changes
    params.set('page', '1');

    // Navigate with new params
    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  };

  // Render clear button or search icon
  const renderClearOrSearchIcon = () => {
    if (!searchQuery) {
      return (
        <div className={styles['search-icon']}>
          <SearchIcon strokeColor={COLORS.lightGrey} />
        </div>
      );
    }

    return (
      <span onClick={handleClearSearch} className={styles['input-clear']}>
        <CloseSVG strokeColor={COLORS.grey} width={20} height={20} />
      </span>
    );
  };

  return (
    <form onSubmit={handleSubmit} className={styles['search-input-container']}>
      <div className={styles['input-container']}>
        <input
          type="search"
          value={searchQuery}
          onChange={(e) => handleInputChange(e.target.value)}
          className={styles.input}
          placeholder=" "
          aria-label="Search books"
        />
        <span
          className={classNames(styles.placeholder, {
            [styles['placeholder-hidden']]: !inputIsEmpty,
          })}
        >
          Search books
        </span>
        {renderClearOrSearchIcon()}
      </div>
    </form>
  );
}
